import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
//import axios from "axios";
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';

function Customer(props) {
  const navigate = useNavigate();
  const { customer } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, cart } = state;
  //const [custInfo, setCustInfo] = useState('');

  useEffect(() => {
    if (!userInfo) {
      navigate('/?redirect=/customer');
    }
  }, [userInfo, navigate]);

  const LinkToCartHandler = async (customerInfo) => {
    //setCustomerCode(customer.CsCode);
    ctxDispatch({
      type: 'SAVE_CUSTOMER',
      payload: customerInfo,
    });

    localStorage.setItem('customerInfo', JSON.stringify(customerInfo));
    navigate('/products');
  };

  return (
    <Card className="card-prod">
      <Card.Body className="card-prod-body">
        <Row className={'row-prod '}>
          <Col
            xs={7}
            className={
              'prod-col ' +
              (customer.CsCode === cart.customerInfo.CsCode
                ? 'selected-row'
                : '')
            }
          >
            {customer.CsCode}- {customer.CsName} (<span style={{fontWeight:'bold', color:customer.CsNtnStatus === 'F'? 'green' : 'red'}}>{customer.CsNtnStatus === 'F' ? 'Filer': 'Non-filer'}</span>)
            <br/>
            NTN: {customer.CsNtNo}
            <br/>
            {customer.CsCredit && parseFloat(customer.CsCredit) !== 0
            ? `Credit: ${parseFloat(customer.CsCredit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`:''}
            {customer.CsReturn && parseFloat(customer.CsReturn) !== 0
            ? (parseFloat(customer.CsCredit) !== 0 ? ` & Return: `: `Return: `)+`${parseFloat(customer.CsReturn).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`:''}
          </Col>
          <Col
            xs={3}
            className={
              'prod-col ' +
              (customer.CsCode === cart.customerInfo.CsCode
                ? 'selected-row'
                : '')
            }
          >
            {customer.MarketName}
          </Col>
          {/* <Col xs={1} className="prod-col text-end">
              {customer.CsCredit?parseFloat(customer.CsCredit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '0.00'}          </Col>
          <Col xs={1} className="prod-col text-end">
            {customer.CsReturn?parseFloat(customer.CsReturn).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '0.00'}
          </Col> */}
          <Col xs={2} className={'prod-col'}>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={() => LinkToCartHandler(customer)}
              style={{ fontSize: '12px' }}
            >
              Select
            </Button>
          </Col>
        </Row>
        {/* <Link to={`/customer/${customer.CsCode}`}>
          <Card.Title>
            {customer.CsCode} - {customer.CsName}
          </Card.Title>
        </Link> */}

        {/* <Row>
          <Card.Text>Code : {customer.CsCode}</Card.Text>
        </Row>
        <Row>
          <Card.Text>Name : {customer.CsName} %</Card.Text>
        </Row> */}
        {/* <Row>
          <Card.Text>CNIC : {customer.CsCNIC}</Card.Text>
        </Row>
        <Row>
          <Card.Text>NTNO : {customer.CsNTNO}</Card.Text>
        </Row>
        <Row>
          <Card.Text>Address : {customer.MarketName}</Card.Text>
        </Row>
        <Button onClick={() => LinkToCartHandler(customer)}>
          Link to Cart
        </Button> */}
      </Card.Body>
    </Card>
  );
}

export default Customer;
