import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/esm/Form';
import Axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../util';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';

export default function SigninScreen() {
  const naviagte = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, customers, products, cli } = state;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const { data } = await Axios.post('/api/users/signin', {
        code,
        password,
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });

      localStorage.setItem('cli', JSON.stringify(data));
      ctxDispatch({ type: 'USER_CLI', payload: data.code });

      const result = await Axios.get('/api/products');
      ctxDispatch({ type: 'GET_PRODUCTS', payload: result.data });
      localStorage.setItem('products', JSON.stringify(result.data));
      if (Object.keys(result.data).length === 0) {
        setError('Products not found');
        toast.error('Products not found');
      }

      const customers = await Axios.get(`/api/customers/${data.code}`);
      ctxDispatch({ type: 'GET_CUSTOMERS', payload: customers.data });
      localStorage.setItem('customers', JSON.stringify(customers.data));
      if (Object.keys(customers.data).length === 0) {
        setError('customers not found');
        toast.error('customers not found');
      }
      setLoading(false);
      //naviagte(redirect || '/customer');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    // if (userInfo && !cli)
    // {
    //   naviagte('/cli');
    // }
    // else 
    if (
      userInfo &&
      // cli &&
      customers &&
      products &&
      Object.keys(customers).length > 0 &&
      Object.keys(products).length > 0
    ) {
      naviagte(redirect);
    } else if (userInfo && customers && products && cli) {
      ctxDispatch({ type: 'USER_SIGNIN_AGAIN' });
      localStorage.removeItem('userInfo');
      localStorage.removeItem('cli');
      localStorage.removeItem('customers');
      localStorage.removeItem('products');
      naviagte('/');
    }
  }, [naviagte, redirect, userInfo, customers, products, cli, ctxDispatch]);

  return (
    <Container className="small-container">
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <h1 className="my-3">Sign In</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="Code">
          <Form.Label>User Id</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => {
              setCode(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>

        <div className="mb-3">
          <Button type="submit">Sign In</Button>
        </div>
      </Form>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div></div>
      )}
    </Container>
  );
}
